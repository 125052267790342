<template>
  <div>
    <div class="box_title" style="margin-top:4rem">
       <h2 class="h3">{{ $t('content.signup.popup.IntegratedUser.txt01') }}</h2>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(doNext)">
        <fieldset class="form-container" style="margin-top:0">
          <legend>{{ $t("signUp.title.user") }}</legend>
          <div>
            <ul>
              <!-- 이메일 -->
              <li class="form-row">
                <BaseLabelElement
                  :label="$t('content.event.Event.txt51')" />
                <BaseInput
                  v-model="userInfo.email"
                  id="snsName"
                  disabled
                  :invalid="isDuplicateEmail"
                />
                <span
                  v-if="isDuplicateEmail"
                  class="input-error-desc"
                  v-html="$t('content.signup.popup.DuplicatedEmail.txt02',
                  { snsName: userInfo.snsName, snsRole: snsRole })"
                ></span>
                <span
                  v-else
                  class="input-desc"
                >{{ $t('content.signup.OauthUserInfo.txt02') }}</span>
              </li>

              <!-- 사용자 닉네임 -->
              <li class="form-row">
                <BaseLabelElement :label="$t('content.event.Event.txt50')" />
                <ValidationProvider
                  :name="$t('content.signup.OauthUserInfo.txt03')"
                  :rules="{ required: true, regex: /^[ㄱ-ㅎ가-힣a-zA-Z0-9 ]+$/ }"
                  v-slot="{ errors, failed, invalid }">
                  <BaseInput
                    v-model="name"
                    id="userName"
                    :placeholder="$t('content.signup.OauthUserInfo.txt03')"
                    :invalid="failed"
                  />
                  <span
                    v-if="invalid"
                    class="input-error-desc">{{$t('content.signup.OauthUserInfo.txt03')}} is not valid
                  </span>
                </ValidationProvider>
              </li>
            </ul>
          </div>
        </fieldset>

        <fieldset class="form-container signup_step3">
          <legend>{{ $t("signUp.title.agreement") }}</legend>
          <div class="terms-area">
            <!-- 전체 동의 -->
            <div class="check-all">
              <div class="check_link">
                <div @click="onAllAgreedChange">
                  <BaseCheckbox id="allAgree" v-model="allAgreed" disabled>
                  {{ $t('content.signup.Agreement.txt01') }}</BaseCheckbox>
                </div>
              </div>
            </div>
            <!-- // 전체 동의 -->
            <div class="terms-container">
                <!-- 회원 가입 약관 (필수) -->
                <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
                <ValidationProvider :name="$t('content.signup.Agreement.txt02')"
                  class="check_link"
                  :rules="{ required: { allowFalse: false } }">
                  <BaseCheckbox id="agree1" v-model="joinAgreed" required disabled>
                    {{ $t('content.signup.Agreement.txt02') }}</BaseCheckbox>
                  <a href="#" class="btn_arrow"
                    @click="openPopup('joinParams')"><span class="blind">more</span></a>
                </ValidationProvider>
                <!-- // 회원 가입 약관 (필수) -->
                <!-- 개인정보 수집 및 이용 (필수) -->
                <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
              <ValidationProvider :name="$t('content.signup.Agreement.txt06')"
                class="check_link"
                :rules="{ required: { allowFalse: false } }">
                <BaseCheckbox id="agree2" v-model="privateMandatoryAgreed" required disabled>
                  {{ $t('content.signup.Agreement.txt06') }}</BaseCheckbox>
                  <a href="#" class="btn_arrow"
                    @click="openPopup('privacyParams')"><span class="blind">more</span></a>
              </ValidationProvider>
              <!-- 개인정보 수집 및 이용 (필수) -->

              <!-- 개인정보 수집 및 이용 (필수) -->
              <ValidationProvider :name="$t('content.signup.Agreement.txt07')"
                class="check_link"
                :rules="{ required: { allowFalse: false } }">
                <BaseCheckbox id="agree3" v-model="privateOptionalAgreed" required disabled>
                  {{ $t('content.signup.Agreement.txt07') }}</BaseCheckbox>
                  <a href="#" class="btn_arrow"
                    @click="openPopup('privacyUseParams')"><span class="blind">more</span></a>
              </ValidationProvider>
              <!-- 개인정보 수집 및 이용 (필수) -->

              <!-- 개인정보 취급업무 위탁 (필수) -->
              <ValidationProvider :name="$t('content.signup.Agreement.txt09')"
                class="check_link"
                :rules="{ required: { allowFalse: false } }">
                <BaseCheckbox id="agree5" v-model="managementAgreed" required disabled>
                  {{ $t('content.signup.Agreement.txt09') }}</BaseCheckbox>
                  <a href="#" class="btn_arrow"
                    @click="openPopup('managementParams')"><span class="blind">more</span></a>
              </ValidationProvider>
              <!-- 개인정보 취급업무 위탁 (필수) -->

              <!-- 14세 이상 (필수) -->
              <ValidationProvider :name="$t('content.signup.Agreement.txt08')"
                class="check_link"
                :rules="{ required: { allowFalse: false } }">
                <BaseCheckbox id="agree4" v-model="ageAgreed" required disabled>
                  {{ $t('content.signup.Agreement.txt08') }}</BaseCheckbox>
                  <a href="#" class="btn_arrow"
                    @click="openPopup('ageParams')"><span class="blind">more</span></a>
              </ValidationProvider>
              <!-- 14세 이상 (필수) -->
            </div>
          </div>
        </fieldset>
        <!-- 버튼 -->
        <div class="btn_bottom_wrap fixed">
<!--          <BaseButton-->
<!--            type="line"-->
<!--            @click="cancel"-->
<!--          >{{$t('content.common.button.btn05')}}</BaseButton>-->
          <BaseButton
            nativeType="submit"
            :disabled="invalid || isDuplicateEmail"
          >{{$t('content.signin.SignIn.txt10')}}</BaseButton>
        </div>
      </form>
    </ValidationObserver>
    <JoinAgreePopup
      :allOpen="openParam"
      :params="joinParams"
      @joinAgree="joinAgree"
    />
    <PrivacyAgreePopup
      :allOpen="openParam"
      :params="privacyParams"
      @privacyAgree="privacyAgree"
    />
    <PrivacUseAgreePopup
      :allOpen="openParam"
      :params="privacyUseParams"
      @privacyUseAgree="privacyUseAgree"
    />
    <AgeAgreedPopup
      :allOpen="openParam"
      :params="ageParams"
      @privacyAgeAgree="privacyAgeAgree"
    />
    <ManagementAgreedPopup
      :allOpen="openParam"
      :params="managementParams"
      @privacyManagementAgree="privacyManagementAgree"
    />
  </div>
</template>

<script>
import SignUpStep2 from '@/views/auth/SignUpStep2.vue';

import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import sha512 from 'sha512'; // http://cryptocoinjs.com/modules/crypto/sha512/
import { APIs } from '@/common/portalApi';
import JoinAgreePopup from '@/components/popup/JoinAgreePopup.vue';
import PrivacyAgreePopup from '@/components/popup/PrivacyAgreePopup.vue';
import AgeAgreedPopup from '@/components/popup/AgeAgreedPopup.vue';
import ManagementAgreedPopup from '@/components/popup/ManagementAgreedPopup.vue';
import PrivacUseAgreePopup from '@/components/popup/PrivacUseAgreePopup.vue';

export default {
  name: 'MobileSignUpStep2',
  extends: SignUpStep2,
  components: {
    BaseCheckbox,
    BaseLabelElement,
    JoinAgreePopup,
    PrivacyAgreePopup,
    AgeAgreedPopup,
    ManagementAgreedPopup,
    PrivacUseAgreePopup,
  },
  data() {
    return {
      name: this.userInfo.name,
      allAgreed: false,
      joinAgreed: false,
      privateMandatoryAgreed: false,
      privateOptionalAgreed: false,
      dreamAgreed: false,
      ageAgreed: false,
      managementAgreed: false,
      openParam: {
        allOpen: false,
      },
      managementParams: {
        open: false,
      },
      ageParams: {
        open: false,
      },
      privacyUseParams: {
        open: false,
      },
      privacyParams: {
        open: false,
      },
      joinParams: {
        open: false,
      },
    };
  },
  watch: {
    joinAgreed(newVal) {
      this.allAgreed = newVal && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && this.ageAgreed && this.managementAgreed;
    },
    privateMandatoryAgreed(newVal) {
      this.allAgreed = this.joinAgreed && newVal && this.privateOptionalAgreed
        && this.ageAgreed && this.managementAgreed;
    },
    privateOptionalAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && newVal
        && this.ageAgreed && this.managementAgreed;
    },
    ageAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && newVal && this.managementAgreed;
    },
    managementAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && this.ageAgreed && newVal;
    },
  },
  methods: {
    openPopup(name) {
      this[name].open = true;
    },
    joinAgree(state) {
      this.joinAgreed = state;
      if (this.openParam.allOpen) {
        this.privacyParams.open = true;
      }
    },
    privacyAgree(state) {
      this.privateMandatoryAgreed = state;
      if (this.openParam.allOpen) {
        this.privacyUseParams.open = true;
      }
    },
    privacyUseAgree(state) {
      this.privateOptionalAgreed = state;
      if (this.openParam.allOpen) {
        this.managementParams.open = true;
      }
    },
    privacyAgeAgree(state) {
      this.ageAgreed = state;
      this.openParam.allOpen = false;
    },
    privacyManagementAgree(state) {
      this.managementAgreed = state;
      if (this.openParam.allOpen) {
        this.ageParams.open = true;
      }
    },
    doNext() {
      if (this.isDuplicateEmail) {
        this.showAlert(this.$t('content.signup.UserInfo.txt16'));
      } else {
        this.portalApiClient({
          url: `/auth/sns/signup`,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
          data: {
            email: this.userInfo.email,
            accessToken: this.userInfo.access_token,
            codeRegisterType: this.userInfo.apply_path,
            name: this.userInfo.name,
            fingerPrint: this.userInfo.finger_print,
            isAdmin: false,
          },
        }).then(({ data }) => {
          if (data.result) {
            this.$router.replace({
              name: 'SignUpStep4',
              params: {
                email: this.userInfo.email,
                isSns: true,
              },
            });
          }
        });
      }
    },
    onAllAgreedChange() {
      if (!this.allAgreed) {
        this.openParam.allOpen = true;
        this.joinParams.open = true;
      } else {
        this.allAgreed = !this.allAgreed;
        this.joinAgreed = this.allAgreed;
        this.privateMandatoryAgreed = this.allAgreed;
        this.privateOptionalAgreed = this.allAgreed;
        this.ageAgreed = this.allAgreed;
        this.managementAgreed = this.allAgreed;
      }
    },
  },
  created() {
    this.setFooter(false);
  },
};
</script>
<style>
.form-row label{ margin-bottom: 0.8rem;}
#content.auth{ padding-bottom: 9.6rem;}
.signup_step3 .terms-area .terms-container{ padding: 0.8rem 0;}
.signup_step3 .terms-container .checkbox-container .checkbox{ margin-top:0.8rem; margin-bottom:0.8rem;}
.privacyTable table {
  table-layout: initial !important;
}
</style>
